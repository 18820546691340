import React from 'react';
import logo from '../../assets/capitolIntel-removebg-preview.png'; // Import logo image
import './RegistrationSuccess.css'; // Import CSS file for styling

const RegistrationSuccess = () => {
  return (
    <div className="registration-success-container">
      <img src={logo} alt="Capitol Intel Logo" className="logo" />
      <h1 className="confirmation-heading">Registration Successful!</h1>
      <p className="confirmation-message">
        Please download our mobile app or go to the web app to start using Capitol Intel.
      </p>
      <div className="app-download-links">
        <a href="https://apps.apple.com/us/app/capitol-intel/id6470370567" className="app-store-link">IPhone</a>
        <a href="https://play.google.com/store/apps/details?id=com.jacoblovins.clarityMobile" className="play-store-link">Android</a>
        <a href="https://capitolintel.clarityinfo.org" className="web-app-link">Web App</a>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
