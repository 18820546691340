import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { db, storage } from '../../../db/db';
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import UnconfirmedEventsModal from './UnconfirmedEventsModal'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(utc)

function UnconfirmedTable({ committeeData }) {
  const [unconData, setUnconData] = useState([]);
  const [info, setInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [itemsToDelete, setItemsToDelete] = useState([])
  const [fileList, setFileList] = useState()
  const [submitting, setSubmitting] = useState();
  const [quillValue, setQuillValue] = useState();
  const [noDate, setNoDate] = useState(false);
  const [uponAdjourn, setUponAdjourn] = useState(false);
  const [useAgenda, setUseAgenda] = useState(false);
  const [loading, setLoading] = useState(false)

  const unconfirmedEventCol = [
    {
      title: 'Change Type',
      dataIndex: 'changeType',
      key: 'changeType',
      width: 65,
    },
    {
      title: 'Subject',
      key: 'subject',
      dataIndex: 'subject',
      width: 250,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.billsResolutions)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.committees)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.start)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: 'Date/Time',
      dataIndex: 'start',
      key: 'start',
      width: 100,
      render: date => date ? dayjs(date).utc().tz('America/New_York').format("YYYY-MM-DD hh:mm a") : null
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      width: 100,
    },
    {
      title: 'Bills/Resolutions',
      key: 'billsResolutions',
      dataIndex: 'billsResolutions',
      width: 200,
    },
    {
      title: 'Agenda Link',
      key: 'useAgenda',
      dataIndex: 'useAgenda',
      width: 63,
      ellipsis: true,
      render: useAgenda => useAgenda ? null : <CheckOutlined />
    },
    {
      title: 'Agenda',
      key: 'useAgenda',
      dataIndex: 'useAgenda',
      width: 50,
      ellipsis: true,
      render: useAgenda => useAgenda ? <CheckOutlined /> : null
    },
    {
      title: 'Docs #',
      key: 'docCount',
      dataIndex: 'docCount',
      width: 50,
      ellipsis: true,
    },
  ];

  const getEventData = () => {
    setLoading(true)
    const unconfirmedArr = [];
    // const trackedArr = [];
    db.collection('unconfirmedEvents').get()
      .then(result => result.docs)
      .then(docs => docs.map(doc => doc.data()))
      .then(async (docs) => {
        for (let i = 0; i < docs.length; i += 1) {
          await db.collection('trackedEvents').doc(docs[i].id).get()
            .then(result => result.data())
            .then(data => {
              let isAgenda = false;
              let isAgendaLink = false;
              // const committees = data?.committees?.join(", ");
              const bills = data?.billsResolutions?.join(", ");
              const added = data?.adminAdded
              const docsArr = [];
              let quill;
              if (data?.agenda) {
                setQuillValue(JSON.parse(data.agenda))
                isAgenda = data.agenda === "\"<p><br></p>\"" ? false : true
                quill = JSON.parse(data.agenda)
              }
              if (data?.agendaUri) {
                isAgendaLink = true
              }
              if (data?.extraDocuments?.length > 0) {
                for (let j = 0; j < data.extraDocuments.length; j += 1) {
                  docsArr.push(data.extraDocuments[j])
                }
              }
              unconfirmedArr.push({
                adminAdded: added,
                pageType: 'Event',
                changeType: docs[i].changeType,
                key: docs[i].id,
                subject: docs[i].subject,
                id: docs[i].id,
                start: docs[i].start,
                end: docs[i].end,
                body: docs[i].body,
                chamber: docs[i].chamber,
                isVimeo: docs[i].isVimeo,
                willBroadcast: docs[i].willBroadcast,
                location: docs[i].location,
                livestreamUrl: docs[i].livestreamUrl,
                committees: docs[i].committees,
                billsResolutions: bills,
                agendaLinkBool: isAgendaLink,
                agendaLink: docs[i].agendaUri,
                docCount: docs[i].extraDocuments?.length,
                extraDocuments: docsArr,
                agendaBool: isAgenda,
                agenda: quill ? quill : null,
                noDate: docs[i].start ? true : docs[i].noDate ? docs[i].noDate : false,
                uponAdjourn: docs[i].uponAdjourn,
                adjournText: docs[i].adjournText,
                trackedSubject: data?.subject ? data.subject : null,
                trackedStart: data?.start ? data.start : null,
                trackedEnd: data?.end ? data.end : null,
                trackedLocation: data?.location ? data.location : null,
                trackedLivestreamUrl: data?.livestreamUrl ? data.livestreamUrl : null,
                trackedAgendaLink: data?.agendaUri ? data.agendaUri : null,
              })
            })
          }
        })
        .then(arr => {
          unconfirmedArr.sort(function (a, b) {
            var dateA = new Date(a.start);
            var dateB = new Date(b.start);
            if (!b.start) {
              return 1;
            }
            if (!a.start) {
              return -1;
            }
            return dateB - dateA;
          });
          setUnconData(unconfirmedArr)
          setLoading(false)
        })
  }

  useEffect(() => {
    getEventData()
  }, [])

  const rowClicked = (e, record) => {
    const data = {
      ...record,
      start: record.start ? record.start : dayjs().utc().tz('America/New_York'),
      end: record.end ? record.end : dayjs(record.start).utc().tz('America/New_York').add(30, 'minute'),
    }
    setNoDate(record.start ? false : record.noDate ? record.noDate : false)
    setUponAdjourn(record.uponAdjourn ? record.uponAdjourn : false)
    setInfo(data)
    setItemsToDelete([])
  };

  const onDeleteEvent = (id) => {
    db.collection('unconfirmedEvents').doc(id).delete()
      .then(() => {
        getEventData();
        setOpen(false);
      })
  };

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter(file => file.status !== "error"));

  const onRemove = async file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const storageUpload = async (saveData) => {
    setSubmitting(true);
    if (fileList?.length > 0) {
      const fileArr = [];
      await Promise.all(
        fileList?.map(async file => {
          const fileName = `${file.name}-${Date.now()}`
          const pdfRef = ref(storage, `${saveData.id}/${fileName}`);
          try {
            const upload = await uploadBytes(pdfRef, file.originFileObj)
            const downloadUrl = await getDownloadURL(pdfRef);
            const item = {
              name: fileName,
              url: downloadUrl,
            };
            fileArr.push(item)
          } catch (e) {
            console.log(e);
          }
        })
      )
      return fileArr
    } else {
      return []
    }
  }

  const antdUpload = async (saveData) => {
    try {
      const storedFiles = await storageUpload(saveData)
      const startDate = dayjs(saveData.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      const endDate = dayjs(saveData.end).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      const bills = saveData.billsResolutions?.replace(/\s/g, '').split(',')
      const quillStore = JSON.stringify(quillValue);
      // const committees = saveData.committees?.split(', ')
      saveData.key = saveData.id;
      saveData.pageType = 'Event'
      if (info.adminAdded === undefined) {
        db.collection('trackedEvents').doc(info.id).set({
          adminAdded: false,
          id: saveData.id,
          agendaUri: saveData.agendaLink ? saveData.agendaLink : null,
          livestreamUrl: saveData.livestreamUrl ? saveData.livestreamUrl : null,
          location: saveData.location ? saveData.location : null,
          start: saveData.start ? startDate : null,
          end: noDate ? null : endDate,
          subject: saveData.subject ? saveData.subject : null,
          committees: saveData.committees ? saveData.committees : null,
          billsResolutions: bills ? bills : null,
          body: info.body ? info.body : null,
          chamber: info.chamber ? info.chamber : null,
          isVimeo: info.isVimeo ? info.isVimeo : null,
          willBroadcast: info.willBroadcast ? info.willBroadcast : null,
          extraDocuments: info.extraDocuments ? [...storedFiles, ...info.extraDocuments] : [...storedFiles],
          agenda: quillStore ? quillStore : null,
          noDate: noDate,
          uponAdjourn: noDate ? false : uponAdjourn,
          adjournText: saveData.adjournText ? saveData.adjournText : null,
          useAgenda: useAgenda,
        })
          .then(() => {
            db.collection('unconfirmedEvents').doc(info.id).delete()
              .then(() => {
                getEventData();
                setOpen(false);
              })
          })
        db.collection('events').doc(info.id).update({
          billsResolutions: bills ? bills : null,
          committees: saveData.committees ? saveData.committees : null,
          extraDocuments: info.extraDocuments ? [...storedFiles, ...info.extraDocuments] : [...storedFiles],
          ignoreListener: true,
        })
      } else {
        db.collection('trackedEvents').doc(info.id).update({
          adminAdded: true,
          agendaUri: saveData.agendaLink ? saveData.agendaLink : null,
          livestreamUrl: saveData.livestreamUrl ? saveData.livestreamUrl : null,
          location: saveData.location ? saveData.location : null,
          start: saveData.start ? startDate : null,
          end: noDate ? null : endDate,
          subject: saveData.subject ? saveData.subject : null,
          committees: saveData.committees ? saveData.committees : null,
          billsResolutions: bills ? bills : null,
          body: info.body ? info.body : null,
          chamber: info.chamber ? info.chamber : null,
          isVimeo: info.isVimeo ? info.isVimeo : null,
          willBroadcast: info.willBroadcast ? info.willBroadcast : null,
          extraDocuments: info.extraDocuments ? [...storedFiles, ...info.extraDocuments] : [...storedFiles],
          agenda: quillStore ? quillStore : null,
          noDate: noDate,
          uponAdjourn: noDate ? false : uponAdjourn,
          adjournText: saveData.adjournText ? saveData.adjournText : null,
          useAgenda: useAgenda,
        })
          .then(() => {
            db.collection('unconfirmedEvents').doc(info.id).delete()
              .then(() => {
                getEventData();
                setOpen(false);
              })
          })
        db.collection('events').doc(info.id).update({
          billsResolutions: bills ? bills : null,
          committees: saveData.committees ? saveData.committees : null,
          extraDocuments: info.extraDocuments ? [...storedFiles, ...info.extraDocuments] : [...storedFiles],
          ignoreListener: true,
        })
      }

      setFileList([]);
    } catch (err) {
      console.log(err);
      console.log(`Error adding pdf.`, 2);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = (fileName) => {
    const newArr = [...itemsToDelete, fileName]
    setItemsToDelete(newArr)
    setInfo({
      ...info,
      extraDocuments: info.extraDocuments.filter(item => item.name !== fileName)
    })
  };

  const onSubmitDelete = () => {
    for (let i = 0; i < itemsToDelete.length; i += 1) {
      const pdfRef = ref(storage, `${info.id}/${itemsToDelete[i]}`)
      deleteObject(pdfRef).then(() => {
        // File deleted successfully
      })
    }
    setItemsToDelete([])
  };

  const onAcceptChanges = () => {
    if (info.changeType === 'deleted') {
      db.collection('trackedEvents').doc(info.id).delete()
        .then(() => {
          db.collection('unconfirmedEvents').doc(info.id).delete()
            .then(() => {
              getEventData();
              setOpen(false);
            })
        })
    } else {
      const startDate = dayjs(info.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      const endDate = info.end ? dayjs(info.end).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : null;
      info.key = info.id;
      info.pageType = 'Event'

      db.collection('trackedEvents').doc(info.id).set({
        adminAdded: false,
        agendaUri: info.agendaLink ? info.agendaLink : null,
        livestreamUrl: info.livestreamUrl ? info.livestreamUrl : null,
        location: info.location ? info.location : null,
        start: info.start ? startDate : null,
        end: endDate ? endDate : null,
        subject: info.subject ? info.subject : null,
        noDate: noDate,
      }, { merge: true })
        .then(() => {
          db.collection('unconfirmedEvents').doc(info.id).delete()
            .then(() => {
              getEventData();
              setOpen(false);
            })
        })
    }
  };

  const onSubmit = async (saveData) => {
    const antdstuff = await antdUpload(saveData)
    onSubmitDelete()
  };

  const centerPagination = "bottomCenter";
  return (
    <div>
      <Input.Search
        placeholder='Search Unconfirmed Events'
        style={{ marginBottom: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value)
        }}
      />
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            rowClicked(event, record);
          },
        })}
        bordered
        size={'small'}
        loading={loading}
        columns={unconfirmedEventCol}
        dataSource={unconData}
        pagination={{
          position: [centerPagination],
          hideOnSinglePage: true,
        }}
        scroll={{ y: 500 }}
        footer={() =>
          <UnconfirmedEventsModal
            info={info}
            setInfo={setInfo}
            tableType={'Unconfirmed Event'}
            onSubmit={onSubmit}
            onDeleteEvent={onDeleteEvent}
            onAcceptChanges={onAcceptChanges}
            open={open}
            setOpen={setOpen}
            committeeData={committeeData}
            handleDelete={handleDelete}
            handleChange={handleChange}
            onRemove={onRemove}
            submitting={submitting}
            setFileList={setFileList}
            fileList={fileList}
            quillValue={quillValue}
            setQuillValue={setQuillValue}
            noDate={noDate}
            setNoDate={setNoDate}
            uponAdjourn={uponAdjourn}
            setUponAdjourn={setUponAdjourn}
            useAgenda={useAgenda}
            setUseAgenda={setUseAgenda}
          />
        }
      />
    </div>
  );
}

export default UnconfirmedTable;