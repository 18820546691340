import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Spin } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import Logo from '../assets/capitolIntel-removebg-preview.png';
import 'antd/dist/reset.css'; // Import Ant Design styles
import './SignUp.css'; // Import custom CSS for autofill and other styles

const { Title } = Typography;

const ExtraUsers = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const emailDomain = location.state?.emailDomain;
    const [loading, setLoading] = useState(false); // State to manage loading indicator

    useEffect(() => {
        if (!emailDomain) {
            window.location.href = "https://www.clarityinfo.org";
        }
    }, [emailDomain]);

    const handleAddUsers = async (values) => {
        const { email1, email2 } = values;

        setLoading(true); // Start loading indicator

        try {
            const response = await fetch('https://api.clarityinfo.org/add-extra-users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email1, email2, emailDomain }),
            });

            const result = await response.json();
            if (result.success) {
                navigate("/registrationSuccess");
            } else {
                alert(result.message || 'There was an error processing your request. Please try again later.');
            }
        } catch (error) {
            console.error('Error adding users:', error);
            alert('There was an error processing your request. Please try again later.');
        } finally {
            setLoading(false); // Stop loading indicator
        }
    };

    return (
        <Row justify="center" style={{ minHeight: '100vh', backgroundColor: '#121212', color: '#E2E2E2' }}>
            <Col xs={24} sm={16} md={12} lg={8} xl={6}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <div className="logo-container sign-up-form">
                        <img src={Logo} alt="CAPITOL INTEL" className="logo" />
                    </div>
                    <Title level={3} style={{ color: '#FFFFFF', margin: '20px 0' }}>Add Your Extra Users</Title>
                    <p style={{ color: '#FFFFFF' }}>Emails added must have the same domain as the email used to sign up</p>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleAddUsers}
                        className="form-container sign-up-form"
                        style={{ backgroundColor: '#232021', padding: '20px', borderRadius: '8px' }}
                    >
                        <Form.Item
                            name="email1"
                            label={<span style={{ color: '#E2E2E2' }}>Email 1</span>}
                            rules={[{ required: true, message: 'Please input the first email!', type: 'email' }]}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item
                            name="email2"
                            label={<span style={{ color: '#E2E2E2' }}>Email 2</span>}
                            rules={[{ required: true, message: 'Please input the second email!', type: 'email' }]}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%', backgroundColor: '#009090', borderColor: '#009090' }}
                                disabled={loading} // Disable button during loading
                            >
                                {loading ? <Spin /> : 'Submit'} {/* Show spinner if loading */}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    );
};

export default ExtraUsers;