import React, { useState, useEffect } from "react";
import { Table, Select, Input } from "antd";
import { db } from '../../../db/db'
import CalendarsModal from './CalendarsModal'

function CalendarsTable() {
  const [data, setData] = useState()
  const [datesLoaded, setDatesLoaded] = useState(false);
  const [datesJson, setDatesJson] = useState();
  const [dateData, setDateData] = useState([{ label: '', value: '1' }])
  const [dateValue, setDateValue] = useState('0');
  const [info, setInfo] = useState({})
  // const [allData, setAllData] = useState();
  const [dataFromDB, setDataFromDB] = useState();
  // const [houseData, setHouseData] = useState();
  // const [senateData, setSenateData] = useState();
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [quillValue, setQuillValue] = useState();

  const documentOptions = {
    '1': 'General Calendar',
    '2': 'Rules Calendar',
    '4': 'Local Calendar',
    '8': 'Miscellaneous Calendar',
    '16': 'First Readers',
    '32': 'Daily Status',
    '512': 'Composite',
  }

  const calendarsCol = [
    {
      title: 'Calendar Name',
      dataIndex: 'title',
      key: 'title',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.title)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.billsResolutions)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.chamber)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: 'Bills/Resolutions',
      key: 'billsResolutions',
      dataIndex: 'billsResolutions',
      ellipsis: true,
    },
    {
      title: 'Attachment Link',
      key: 'attachmentLink',
      dataIndex: 'attachmentLink',
      ellipsis: true,
      render: attachments => attachments ? <p>True</p> : <p>False</p>
    },
    {
      title: 'Attachments',
      key: 'attachments',
      dataIndex: 'attachments',
      ellipsis: true,
      render: attachments => attachments ? <p>True</p> : <p>False</p>
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: item => documentOptions[`${item}`]
    },
    {
      title: 'Chamber',
      key: 'chamber',
      dataIndex: 'chamber',
    },
  ];

  const getCalendarData = () => {
    if (!datesLoaded) {
      db.collection('calendarDays').doc('1031').get()
        .then(doc => {
          const items = doc.data()
          return items
        })
        .then(calendars => calendars.days.map(doc => ({
          date: doc.date,
          day: doc.day,
        })))
        .then(days => {
          setDatesJson(days)
          const datesArr = [];
          for (let i = 0; i < days.length; i += 1) {
            datesArr.push({
              label: `Day ${days[i].day}: ${days[i].date.substring(0, 10)}`,
              value: i,
            })
          }
          return datesArr
        }).then(dates => {
          setDateData(dates)
          setDatesLoaded(true)
        })
    } else {
      const allDataArr = [];
      const houseDataArr = [];
      const senateDataArr = [];
      db.collection('calendars').doc(new Date(datesJson[dateValue].date).toISOString()).get()
        .then(doc => {
          const item = doc.data()
          setDataFromDB(item.calendars)
          return item
        })
        .then(docs => {
          const docsArr = [];
          const calArr = docs.calendars
          for (let i = 0; i < calArr.length; i += 1) {
            let isAttachment = false;
            let quill;
            if (calArr[i].attachments) {
              setQuillValue(JSON.parse(calArr[i].attachments))
              isAttachment = calArr[i].attachments === "\"<p><br></p>\"" ? false : true
              quill = JSON.parse(calArr[i].attachments)
            }
            docsArr.push({
              pageType: 'Calendar',
              chamber: calArr[i].chamber === 1 ? 'House' : 'Senate',
              title: calArr[i].title,
              date: calArr[i].date,
              billsResolutions: calArr[i].billsResolutions?.join(', '),
              type: calArr[i].type,
              attachmentLink: calArr[i].url,
              attachments: quill,
              key: calArr[i].title,
              isAttachment: isAttachment,
            })
          }
          return docsArr
        })
        .then(calendars => {
          for (let i = 0; i < calendars.length; i += 1) {
            allDataArr.push(calendars[i])
            if (calendars[i].chamber === 1) {
              houseDataArr.push(calendars[i])
            } else if (calendars[i].chamber === 2) {
              senateDataArr.push(calendars[i])
            }
          }
          // setAllData(allDataArr);
          // setHouseData(houseDataArr);
          // setSenateData(senateDataArr);
          setData(allDataArr)
        })
    }

  }

  useEffect(() => {
    getCalendarData()
  }, [datesLoaded, dateValue])


  const rowClicked = (e, record) => {
    const data = {
      ...record
    }
    setInfo(data)
  };

  const onSubmit = (saveData) => {
    let updatedArr;
    const bills = saveData.billsResolutions?.replace(/\s/g, '').split(',')
    if (dataFromDB.find(o => o.title === saveData.title)) {
      updatedArr = dataFromDB.map(obj =>
        obj.title === saveData.title ? {
          ...obj,
          chamber: saveData.chamber === 'House' ? 1 : 2,
          billsResolutions: bills ? bills : null,
          title: saveData.title ? saveData.title : null,
          type: saveData.type ? saveData.type : null,
          url: saveData.attachmentLink ? saveData.attachmentLink : null,
        } : obj
      );
    } else {
      dataFromDB.push({
        chamber: saveData.chamber === 'House' ? 1 : 2,
        billsResolutions: bills ? bills : null,
        title: saveData.title ? saveData.title : null,
        type: saveData.type ? saveData.type : null,
        url: saveData.attachmentLink ? saveData.attachmentLink : null,
      })
      updatedArr = dataFromDB
    }
    db.collection('calendars').doc(new Date(datesJson[dateValue].date).toISOString()).update({ calendars: updatedArr })
      .then(() => {
        getCalendarData();
        setOpen(false);
      })
  };

  const onClick = (value) => {
    setDateValue(`${value}`)
  };

  const menuProps = {
    items: dateData,
    onClick,
  };
  const centerPagination = "bottomCenter";
  return (
    <div>
      <Select
        style={{ width: 180 }}
        className='calendarDropdown'
        showSearch
        placeholder='Search Calendars'
        // defaultValue={dateData[0]}
        value={dateData[dateValue]}
        optionFilterProp="label"
        onChange={onClick}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={dateData}
      />
      <Input.Search
        placeholder='Search Calendars'
        style={{ marginBottom: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value)
        }}
      />
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            rowClicked(event, record);
          },
        })}
        bordered
        size={'small'}
        columns={calendarsCol}
        dataSource={data}
        pagination={{
          position: [centerPagination],
          hideOnSinglePage: true,
        }}
        scroll={{ y: 500 }}
        footer={() =>
          <CalendarsModal
            info={info}
            onSubmit={onSubmit}
            open={open}
            setOpen={setOpen}
            quillValue={quillValue}
            setQuillValue={setQuillValue}
          />
        }
      />
    </div>
  );
}

export default CalendarsTable