import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { db } from '../../../db/db';
import OOGNewsModal from './OOGNewsModal'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function OOGNewsTable() {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const oogCol = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.subject)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.emailBody)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.start)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: 'Date',
      dataIndex: 'start',
      key: 'date',
      width: 200,
      render: date => date ? dayjs(date).utc().tz('America/New_York').format("YYYY-MM-DD") : null
    },
    {
      title: 'Body',
      key: 'emailBody',
      dataIndex: 'emailBody',
      ellipsis: true,
    },
  ];

  const getOOGData = () => {
    const oogArr = [];
    db.collection('oogNews').get()
      .then(result => result.docs)
      .then(docs => docs.map(doc => doc.data()))
      .then(docs => {
        for (let i = 0; i < docs.length; i += 1) {
          oogArr.push({
            pageType: 'Office of the Governor',
            key: i,
            id: docs[i].id,
            subject: docs[i].subject,
            start: docs[i].start,
            emailBody: docs[i].emailBody,
          })
        }
        oogArr.sort(function (a, b) {
          var dateA = new Date(a.start);
          var dateB = new Date(b.start);
          if (!b.start) {
            return 1;
          }
          if (!a.start) {
            return -1;
          }
          return dateB - dateA;
        });
        setData(oogArr)
      })
  }

  useEffect(() => {
    getOOGData()
  }, [])

  const rowClicked = (e, record) => {
    const data = {
      ...record,
      start: record.start ? record.start : dayjs().utc().tz('America/New_York')
    }
    setInfo(data)
  };

  const deleteEvent = (id) => {
    db.collection('oogNews').doc(id).delete().then(() => {
      alert("Entry successfully deleted!");
      getOOGData();
      setOpen(false);
    }).catch((error) => {
      alert("Error removing entry!");
    });
  };

  const onSubmit = async (saveData) => {
    try {
      const date = saveData.start ? dayjs(saveData.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : null;
      db.collection('oogNews').doc(saveData.id).set({
        id: saveData.id ? saveData.id : null,
        emailBody: saveData.emailBody ? saveData.emailBody : null,
        start: date ? date : null,
        subject: saveData.subject ? saveData.subject : null,
      })
        .then(() => {
          getOOGData();
          setOpen(false);
        })
    } catch (err) {
      console.log(err);
    }
  };

  const centerPagination = "bottomCenter";
  return (
    <div>
      <Input.Search
        placeholder='Search News'
        style={{ marginBottom: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value)
        }}
      />
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            rowClicked(event, record);
          },
        })}
        bordered
        size={'small'}
        columns={oogCol}
        dataSource={data}
        pagination={{
          position: [centerPagination],
          hideOnSinglePage: true,
        }}
        scroll={{ y: 500 }}
        footer={() =>
          <OOGNewsModal
            info={info}
            setInfo={setInfo}
            tableType={'News Entry'}
            onSubmit={onSubmit}
            open={open}
            setOpen={setOpen}
            deleteEvent={deleteEvent}
          />
        }
      />
    </div>
  );
}

export default OOGNewsTable;