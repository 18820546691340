import React from 'react'
import { Card, Space, Form, Input, Button } from 'antd';
import '../../../App.css'

function NotifyUsers() {

    const onFinish = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch('https://api.clarityinfo.org/notifyAllUsers', requestOptions)
            .then(response => alert("Notifications Sent!"));
    };

    return (
        <div>
            <Space align={'center'}>
                <Card
                    title="Notify Users"
                    bordered
                    style={{
                        width: 700,
                    }}
                >
                    <Form onFinish={onFinish} >
                        <Form.Item label="Notification Message" name='notificationMessage'>
                            <Input />
                        </Form.Item>
                        <Form.Item >
                            <Button block type='primary' htmlType='submit'>
                                Send Message to Users
                        </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Space>
        </div>
    )
}

export default NotifyUsers
