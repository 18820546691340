import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import SignUp from './pages/SignUp'
import Admin from './pages/Admin'
import reportWebVitals from './reportWebVitals';
import RegistrationSuccess from './components/signUp/RegistrationSuccess';
import ExtraUsers from './pages/ExtraUsers';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Admin />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/administration" element={<Admin />} />
      <Route path="/registrationSuccess" element={<RegistrationSuccess />} />
      <Route path="/extraUsers" element={<ExtraUsers />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
