import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, Select } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../App.css'

function CalendarsModal({ info, onSubmit, open, setOpen, quillValue, setQuillValue }) {
    const [form] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (info.title) {
            setIsDisabled(true)
            setOpen(true)
        }
    }, [info])

    useEffect(() => {
        form.setFieldsValue(info)
    }, [info, form])

    const showModal = () => {
        setIsDisabled(false)
        form.setFieldsValue({
            title: null,
            billsResolutions: null,
            chamber: null,
            type: null,
            attachmentLink: null,
            attachments: null,
        })
        setOpen(true);
        setQuillValue('')
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onFinish = (data) => {
        onSubmit(data);
    };

    const onChange = (content, delta, source, editor) => {
        setQuillValue(editor.getHTML());
    };

    const documentOptions = [
        {
            value: 1,
            label: 'General Calendar',
        },
        {
            value: 2,
            label: 'Rules Calendar',
        },
        {
            value: 4,
            label: 'Local Calendar',
        },
        {
            value: 8,
            label: 'Miscellaneous Calendar',
        },
        {
            value: 16,
            label: 'First Readers',
        },
        {
            value: 32,
            label: 'Daily Status',
        },
        {
            value: 512,
            label: 'Composite',
        },
    ]

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <>
            <Button type="primary" onClick={showModal}>
                New Entry
      </Button>
            <Modal
                open={open}
                title={'Edit Calendar'}
                onCancel={handleCancel}
                width={750}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="Calendar Name" name='title'>
                            <Input disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Bills/Res"
                            name='billsResolutions'
                            rules={[
                                {
                                    pattern: new RegExp(/^(HB|HR|SB|SR)\d+(,\s(HB|HR|SB|SR)\d+)*$/),
                                    message: "Wrong format!"
                                },
                            ]}
                        >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>
                        <Form.Item label="Document Type" name='type'>
                            <Select
                                options={documentOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Chamber" name='chamber'>
                            <Select
                                options={[
                                    { value: 'House', label: 'House' },
                                    { value: 'Senate', label: 'Senate' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="Attachment Link" name='attachmentLink'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Attachments" name='attachments'>
                            <ReactQuill
                                style={{ height: 280 }}
                                theme="snow"
                                value={quillValue}
                                onChange={onChange}
                                modules={modules}
                                formats={formats}
                            />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 120, marginTop: 50 }}>
                            <Button block type='primary' htmlType='submit'>
                                Save
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default CalendarsModal

