import React, { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button } from 'antd';
import { auth } from '../../db/db'
import { signInWithEmailAndPassword } from "firebase/auth";
import '../../App.css';

function AdminLogin() {
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = (data) => {
        signInWithEmailAndPassword(auth, data.email, data.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
            })
            .catch((error) => {
                const errorCode = error.code;
                setErrorMessage("Email or Password Invalid");
            });
    };


    return (
        <Form onFinish={handleSubmit} className="login-form">
            <Form.Item name='email' rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>

                <Input
                    prefix={<LockOutlined className="site-form-item-icon" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            {errorMessage ? <h3 style={{ color: 'red' }}>{errorMessage}</h3> : null}
            <Form.Item>
                <a className="login-form-forgot" href="">
                    Forgot password
          </a>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                >
                    Log in
          </Button>
            </Form.Item>
        </Form>
    )
}

export default AdminLogin


