import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { db, storage } from '../../../db/db';
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import StatAgModal from './StatAgModal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(utc)

function StatAgTable() {
    const [data, setData] = useState([]);
    const [info, setInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [searchedText, setSearchedText] = useState("");
    const [itemsToDelete, setItemsToDelete] = useState([])
    const [fileList, setFileList] = useState()
    const [submitting, setSubmitting] = useState();
    const [quillValue, setQuillValue] = useState();
    const [noDate, setNoDate] = useState(false);
    const [uponAdjourn, setUponAdjourn] = useState(false);
    const [stateAgencies, setStateAgencies] = useState(false);
    const [useAgenda, setUseAgenda] = useState(false);

    const statAgCol = [
        {
            title: 'State Agency',
            dataIndex: 'statAgs',
            key: 'statAgs',
            width: 200,
            render: item => item.join(', '),
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return String(record.subject)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                    String(record.statAgs)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.start)
                        .toLowerCase()
                        .includes(value.toLowerCase())
            }
        },
        {
            title: 'Subject',
            key: 'subject',
            dataIndex: 'subject',
            width: 200,
        },
        {
            title: 'Date/Time',
            dataIndex: 'start',
            key: 'date',
            width: 80,
            render: date => date ? dayjs(date).utc().tz('America/New_York').format("YYYY-MM-DD hh:mm a") : null
        },
        {
            title: 'Location',
            key: 'location',
            dataIndex: 'location',
            width: 100,
        },
        {
            title: 'Agenda Link',
            key: 'useAgenda',
            dataIndex: 'useAgenda',
            width: 63,
            ellipsis: true,
            render: useAgenda => useAgenda ? null : <CheckOutlined />
        },
        {
            title: 'Agenda',
            key: 'useAgenda',
            dataIndex: 'useAgenda',
            width: 50,
            ellipsis: true,
            render: useAgenda => useAgenda ? <CheckOutlined /> : null
        },
        {
            title: 'Docs #',
            key: 'docCount',
            dataIndex: 'docCount',
            width: 70,
            ellipsis: true,
        },
    ]

    const getAgencyData = () => {
        const eventArr = [];
        db.collection('stateAgencies').doc('statAgs').get()
            .then(result => result.data())
            .then(doc => setStateAgencies(doc.agencies))

        db.collection('statAgEvents').get()
            .then(result => result.docs)
            .then(docs => docs.map(doc => doc.data()))
            .then(async (docs) => {
                for (let i = 0; i < docs.length; i += 1) {
                    let isAgenda = false;
                    let isAgendaLink = false;
                    const docsArr = [];
                    let quill;
                    if (docs[i].agenda) {
                        setQuillValue(JSON.parse(docs[i].agenda))
                        isAgenda = docs[i].agenda === "\"<p><br></p>\"" ? false : true
                        quill = JSON.parse(docs[i].agenda)
                    }
                    if (docs[i].agendaUri) {
                        isAgendaLink = true
                    }
                    if (docs[i].extraDocuments?.length > 0) {
                        for (let j = 0; j < docs[i].extraDocuments.length; j += 1) {
                            docsArr.push(docs[i].extraDocuments[j])
                        }
                    }
                    eventArr.push({
                        key: docs[i].id,
                        subject: docs[i].subject,
                        statAgs: docs[i].statAgs,
                        id: docs[i].id,
                        start: docs[i].start,
                        end: docs[i].end,
                        location: docs[i].location,
                        livestreamUrl: docs[i].livestreamUrl,
                        // agenda: docs[i].agendaUri,
                        docCount: docs[i].extraDocuments?.length,
                        extraDocuments: docsArr,
                        agenda: quill,
                        noDate: docs[i].noDate,
                        uponAdjourn: docs[i].uponAdjourn,
                        adjournText: docs[i].adjournText,
                        agendaLinkBool: isAgendaLink,
                        agendaBool: isAgenda,
                        agendaLink: docs[i].agendaUri,
                        useAgenda: docs[i].useAgenda,
                    })
                }
                eventArr.sort(function (a, b) {
                    var dateA = new Date(a.start);
                    var dateB = new Date(b.start);
                    if (!b.start) {
                        return 1;
                    }
                    if (!a.start) {
                        return -1;
                    }
                    return dateB - dateA;
                });
                setData(eventArr)
            })
    }

    useEffect(() => {
        getAgencyData()
    }, [])

    const rowClicked = (e, record) => {
        const data = {
            ...record,
            start: record.start ? record.start : dayjs().utc().tz('America/New_York'),
            end: record.end ? record.end : dayjs().utc().tz('America/New_York').add(30, 'minute'),
        }
        setNoDate(record.noDate ? record.noDate : false)
        setUponAdjourn(record.uponAdjourn ? record.uponAdjourn : false)
        setInfo(data)
        setItemsToDelete([])
    };

    const handleChange = ({ fileList }) =>
        setFileList(fileList.filter(file => file.status !== "error"));

    const onRemove = async file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        setFileList(newFileList);
    };

    const storageUpload = async (saveData) => {
        setSubmitting(true);
        if (fileList?.length > 0) {
            const fileArr = [];
            await Promise.all(
                fileList?.map(async file => {
                    const fileName = `${file.name}-${Date.now()}`
                    const pdfRef = ref(storage, `${saveData.id}/${fileName}`);
                    try {
                        const upload = await uploadBytes(pdfRef, file.originFileObj)
                        const downloadUrl = await getDownloadURL(pdfRef);
                        const item = {
                            name: fileName,
                            url: downloadUrl,
                        };
                        fileArr.push(item)
                    } catch (e) {
                        console.log(e);
                    }
                })
            )
            return fileArr
        } else {
            return []
        }
    }

    const antdUpload = async (saveData) => {
        try {
            const storedFiles = await storageUpload(saveData)
            const startDate = dayjs(saveData.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            const endDate = dayjs(saveData.end).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            let quillStore = null;
            if (quillValue && quillValue !== "<p><br></p>") {
                quillStore = JSON.stringify(quillValue);
            }
            db.collection('statAgEvents').doc(saveData.id).set({
                id: saveData.id ? saveData.id : null,
                agendaUri: saveData.agendaLink ? saveData.agendaLink : null,
                location: saveData.location ? saveData.location : null,
                livestreamUrl: saveData.livestreamUrl ? saveData.livestreamUrl : null,
                start: startDate,
                end: noDate ? null : endDate,
                statAgs: saveData.statAgs ? saveData.statAgs : null,
                subject: saveData.subject ? saveData.subject : null,
                extraDocuments: info.extraDocuments ? [...storedFiles, ...info.extraDocuments] : [...storedFiles],
                agenda: quillStore ? quillStore : null,
                noDate: noDate,
                uponAdjourn: noDate ? false : uponAdjourn,
                adjournText: saveData.adjournText ? saveData.adjournText : null,
                useAgenda: useAgenda,
            })
                .then(() => {
                    getAgencyData();
                    setOpen(false);
                })

            setFileList([]);
        } catch (err) {
            console.log(err);
        } finally {
            setSubmitting(false);
        }
    };

    const handleDelete = (fileName) => {
        const newArr = [...itemsToDelete, fileName]
        setItemsToDelete(newArr)
        setInfo({
            ...info,
            extraDocuments: info.extraDocuments.filter(item => item.name !== fileName)
        })
    };

    const onSubmitDelete = () => {
        for (let i = 0; i < itemsToDelete.length; i += 1) {
            const pdfRef = ref(storage, `${info.id}/${itemsToDelete[i]}`)
            deleteObject(pdfRef).then(() => {
                // File deleted successfully
            })
        }
        setItemsToDelete([])
    };

    const deleteEvent = (id, files) => {
        for (let i = 0; i < files.length; i += 1) {
            const pdfRef = ref(storage, `${id}/${files[i].name}`)
            deleteObject(pdfRef).then(() => {
                // File deleted successfully
            }).catch((error) => {
                alert("Error removing extra documents!");
            });
        }
        db.collection('statAgEvents').doc(id).delete().then(() => {
            alert("Event successfully deleted!");
            getAgencyData();
            setOpen(false);
            setFileList([]);
        }).catch((error) => {
            alert("Error removing event!");
        });
    };

    const onSubmit = async (saveData) => {
        const antdstuff = await antdUpload(saveData)
        onSubmitDelete()
    };

    const centerPagination = "bottomCenter";
    return (
        <div>
            <Input.Search
                placeholder='Search State Agency Events'
                style={{ marginBottom: 8 }}
                onSearch={(value) => {
                    setSearchedText(value);
                }}
                onChange={(e) => {
                    setSearchedText(e.target.value)
                }}
            />
            <Table
                onRow={(record) => ({
                    onClick: (event) => {
                        rowClicked(event, record);
                    },
                })}
                bordered
                size={'small'}
                columns={statAgCol}
                dataSource={data}
                pagination={{
                    position: [centerPagination],
                    hideOnSinglePage: true,
                }}
                scroll={{ y: 500 }}
                footer={() =>
                    <StatAgModal
                        info={info}
                        setInfo={setInfo}
                        onSubmit={onSubmit}
                        open={open}
                        setOpen={setOpen}
                        handleDelete={handleDelete}
                        handleChange={handleChange}
                        onRemove={onRemove}
                        submitting={submitting}
                        setFileList={setFileList}
                        fileList={fileList}
                        quillValue={quillValue}
                        setQuillValue={setQuillValue}
                        noDate={noDate}
                        setNoDate={setNoDate}
                        uponAdjourn={uponAdjourn}
                        setUponAdjourn={setUponAdjourn}
                        stateAgencies={stateAgencies}
                        useAgenda={useAgenda}
                        setUseAgenda={setUseAgenda}
                        deleteEvent={deleteEvent}
                    />
                }
            />
        </div>
    );
}

export default StatAgTable;