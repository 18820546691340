import React, { useEffect } from 'react'
import { Button, Modal, Form, Input, DatePicker, Popconfirm } from 'antd';
import '../../../App.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function OOGNewsModal(props) {
    const {
        info,
        setInfo,
        tableType,
        onSubmit,
        open,
        setOpen,
        deleteEvent,
    } = props
    const [form] = Form.useForm();
    const { TextArea } = Input;

    useEffect(() => {
        if (info.subject) {
            setOpen(true)
        }
    }, [info])

    useEffect(() => {
        form.setFieldsValue(info)
    }, [info, form])

    const showModal = () => {
        setInfo({
            id: `${new Date().getTime()}`,
            subject: null,
            start: dayjs().utc().tz('America/New_York'),
            emailBody: null,
        })
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setInfo({})
    };

    const onFinish = (data) => {
        onSubmit(data);
    };

    const confirmDelete = (e) => {
        deleteEvent(form.getFieldsValue().id)
    };
    const cancelDelete = (e) => {
        return
    };

    return (
        <>
            <Button className='modalButton' type="primary" onClick={showModal}>
                New Entry
            </Button>
            <Modal
                style={{ top: 10 }}
                open={open}
                title={`Edit ${tableType}`}
                onCancel={handleCancel}
                width={800}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="ID" name='id'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="Subject" name='subject'>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name='start'
                            getValueProps={(i) => ({
                                value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                            })}
                        >
                            <DatePicker
                                allowClear={false}
                                itialValue={dayjs().utc().tz('America/New_York')}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                        <Form.Item label="Body" name='emailBody'>
                            <TextArea autoSize={{ minRows: 4, maxRows: 20 }} />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 160, marginTop: 70 }}>
                            <Button type='primary' htmlType='submit' style={{ width: 200 }}>
                                Save Entry
                            </Button>
                            <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this event?"
                                onConfirm={confirmDelete}
                                onCancel={cancelDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger style={{ marginLeft: 10, width: 200 }}>
                                    Delete Entry
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default OOGNewsModal