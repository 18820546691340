import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, Select, List, Upload, DatePicker, Checkbox, Radio, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import '../../../App.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const { Option } = Select;

function EventsModal(props) {
    const {
        info,
        setInfo,
        tableType,
        onSubmit,
        open,
        setOpen,
        committeeData,
        handleDeleteFiles,
        fileList,
        handleChange,
        onRemove,
        submitting,
        setFileList,
        quillValue,
        setQuillValue,
        noDate,
        setNoDate,
        setUseAgenda,
        uponAdjourn,
        setUponAdjourn,
        deleteEvent,
    } = props

    const [committeeOptions, setCommitteeOptions] = useState();
    const [adjournText, setAdjournText] = useState();
    const [agendaOptions, setAgendaOptions] = useState(1)
    const [agendaDisabled, setAgendaDisabled] = useState(true)
    const [pageUrl, setPageUrl] = useState()
    const [form] = Form.useForm();

    const chamberValues = {
        1: 'House',
        2: 'Senate',
    }

    useEffect(() => {
        const committeeArr = [];
        for (let i = 0; i < committeeData?.length; i += 1) {
            committeeArr.push(<Option key={committeeData[i].id} value={`${committeeData[i].name} (${chamberValues[committeeData[i].chamber]})`}>{committeeData[i].name} ({chamberValues[committeeData[i].chamber]})</Option>)
        }
        setCommitteeOptions(committeeArr)
    }, [committeeData])

    useEffect(() => {
        if (info.key) {
            setOpen(true)
            form.setFieldsValue(info);
            if (info.chamber && info.chamber !== null) {
                setPageUrl(`https://www.legis.ga.gov/schedule/${chamberValues[info.chamber].toLowerCase()}/${info.id}`)
            }
            if (info.useAgenda === true) {
                setAgendaOptions(2)
                setUseAgenda(true)
                setAgendaDisabled(false)
            } else {
                setAgendaOptions(1)
                setUseAgenda(false)
                setAgendaDisabled(true)
            }
        }
    }, [info, form]);

    useEffect(() => {
        form.setFieldsValue(info)
    }, [info, form]);

    const onChange = (content, delta, source, editor) => {
        setQuillValue(editor.getHTML());
    };

    const showNewEntryModal = () => {
        setInfo({
            noWebCal: true,
            pageType: 'Event',
            key: null,
            id: `${new Date().getTime()}`,
            subject: null,
            start: dayjs().utc().tz('America/New_York'),
            end: dayjs().utc().tz('America/New_York').add(30, 'minute'),
            body: null,
            chamber: null,
            isVimeo: null,
            willBroadcast: null,
            location: null,
            livestreamUrl: null,
            committees: [],
            billsResolutions: null,
            agendaLink: null,
            docCount: null,
            extraDocuments: [],
            agenda: null,
            noDate: true,
            uponAdjourn: false,
            adjournText: null,
            useAgenda: false,
        })
        setQuillValue('')
        setNoDate(true)
        setUponAdjourn(false)
        setAdjournText()
        setOpen(true);
        setUseAgenda(false)
        setAgendaDisabled(true)
        setAgendaOptions(1)
        setFileList([]);
    };

    const onNoDateChange = (e) => {
        setNoDate(e.target.checked);
    };

    const onAdjournChange = (e) => {
        setUponAdjourn(e.target.checked);
        if (e.target.checked && !adjournText) {
            setAdjournText("Upon Adjournment");
        }
    };

    const handleStartDateChange = (date, dateString) => {
        if (dateString.length <= 10) {
            dateString = `${dateString} 01:00:00 AM`
        }
        const newStartDate = dayjs(dateString, 'YYYY-MM-DD hh:mm a').tz('America/New_York', true); // Treat the input as EST
        const newEndDate = newStartDate.add(30, 'minute');

        // Update only the start and end fields
        form.setFieldsValue({
            start: newStartDate.utc(), // Convert to UTC for storage
            end: newEndDate.utc(), // Convert to UTC for storage
        });
    };

    const handleCancel = () => {
        setOpen(false);
        setUseAgenda(false)
        setPageUrl()
        setFileList([]);
    };

    const onFinish = (data) => {
        onSubmit(data);
        setPageUrl()
    };

    const deleteFile = (file) => {
        handleDeleteFiles(file)
    };

    const beforeUpload = file => {
        // change to check for pdf
        if (file.type !== "application/pdf") {
            console.log(`${file.name} is not a valid image type`, 2);
            return null;
        }
        return false;
    };

    const onAgendaChange = (e) => {
        if (e.target.value === 1) {
            setAgendaDisabled(true)
            setUseAgenda(false)
        } else {
            setAgendaDisabled(false)
            setUseAgenda(true)
        }
        setAgendaOptions(e.target.value);
    };

    const confirmDelete = (e) => {
        deleteEvent(form.getFieldsValue().id, info.extraDocuments)
    };
    const cancelDelete = (e) => {
        return
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <>
            <Button type="primary" onClick={showNewEntryModal}>
                New Entry
            </Button>
            <Modal
                style={{ top: 10 }}
                open={open}
                title={`Edit ${tableType}`}
                onCancel={handleCancel}
                width={800}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="Web Page">
                            {pageUrl ? <a href={pageUrl} target="_blank">Go to Page</a> : null}
                        </Form.Item>
                        <Form.Item label="ID" name='id'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="Committees" name='committees'>
                            <Select
                                mode="multiple"
                                placeholder="Select a Committee"
                                allowClear
                            >
                                {committeeOptions}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Subject" name='subject'>
                            <Input />
                        </Form.Item>
                        <Checkbox style={{ marginLeft: 147, marginBottom: 12 }} checked={noDate} onChange={onNoDateChange}>TBD</Checkbox>
                        <Form.Item
                            label="Start Time"
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Form.Item
                                    name="start"
                                    style={{ marginBottom: 0, flex: '1' }}
                                    getValueProps={(i) => ({
                                        value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                                    })}
                                >
                                    <DatePicker
                                        allowClear={false}
                                        placeholder="Date"
                                        showTime={noDate ? false : { use12Hours: true, format: 'hh:mm a' }}
                                        format={noDate ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm a'}
                                        placement="bottomLeft"
                                        onChange={handleStartDateChange}
                                    />
                                </Form.Item>
                                <Checkbox
                                    checked={uponAdjourn}
                                    onChange={onAdjournChange}
                                    style={{ marginRight: 4, display: noDate ? 'none' : 'flex' }}
                                />
                                <Form.Item
                                    name="adjournText"
                                    style={{ marginBottom: 0, flex: '1.5', marginLeft: 4, display: noDate ? 'none' : 'block' }}
                                >
                                    <Input
                                        value={adjournText}
                                        onChange={(e) => setAdjournText(e.target.value)}
                                        disabled={!uponAdjourn}
                                        placeholder="Upon Adjournment"
                                    />
                                </Form.Item>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="End Time"
                            name="end"
                            style={{ display: noDate ? 'none' : 'block' }}
                            getValueProps={(i) => ({
                                value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                            })}
                        >
                            <DatePicker
                                allowClear={false}
                                disabled={uponAdjourn}
                                showTime={{ use12Hours: true, format: 'hh:mm a' }}
                                format="YYYY-MM-DD hh:mm a"
                                placement="bottomLeft"
                            />
                        </Form.Item>
                        <Form.Item label="Location" name='location'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Virtual Link" name='livestreamUrl'>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Bills/Res"
                            name='billsResolutions'
                            rules={[
                                {
                                    pattern: new RegExp(/^(HB|HR|SB|SR)\d+(,\s(HB|HR|SB|SR)\d+)*$/),
                                    message: "Wrong format!"
                                },
                            ]}
                        >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>
                        <Radio.Group style={{ marginLeft: 147, marginBottom: 12 }} onChange={onAgendaChange} value={agendaOptions}>
                            <Radio value={1}>Use Agenda Link</Radio>
                            <Radio value={2}>Use Agenda</Radio>
                        </Radio.Group>
                        <Form.Item label="Agenda Link" name='agendaLink' style={{ display: agendaDisabled ? 'block' : 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Agenda" name='agenda' style={{ display: agendaDisabled ? 'none' : 'block' }} >
                            <ReactQuill
                                style={{ height: 280 }}
                                theme="snow"
                                value={quillValue}
                                onChange={onChange}
                                modules={modules}
                                formats={formats}
                            />
                        </Form.Item>
                        <Form.Item label="Extra Documents" style={{ marginTop: 50 }}>
                            <div style={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: 3 }}>
                                <Upload
                                    fileList={fileList}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    onRemove={onRemove}
                                    multiple={true}
                                >
                                    <Button disabled={submitting ? true : false} icon={<UploadOutlined />}>{submitting ? "Uploading" : "Upload"}</Button>
                                </Upload>
                                <List
                                    style={{ marginTop: 10 }}
                                    bordered
                                    dataSource={info.extraDocuments}
                                    renderItem={(item) =>
                                        <List.Item key={item.name} actions={[<Button onClick={() => deleteFile(item.name)}>Delete</Button>]}>
                                            {item.name}
                                        </List.Item>
                                    }
                                />
                            </div>
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 160, marginTop: 70 }}>
                            <Button type='primary' htmlType='submit' style={{ width: 200 }}>
                                Save Event
                            </Button>
                            <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this event?"
                                onConfirm={confirmDelete}
                                onCancel={cancelDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger style={{ marginLeft: 10, width: 200 }}>
                                    Delete Event
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default EventsModal
