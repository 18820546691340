import React, { useState, useEffect } from "react";
import { Table, Input, Button } from "antd";
import { db } from '../../../db/db';
import ContactInfoModal from "./ContactInfoModal";

function ContactInfoTable() {
    const [data, setData] = useState([]);
    const [info, setInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [searchedText, setSearchedText] = useState("");

    const contactCol = [
        {
            title: 'Committee',
            dataIndex: 'committee',
            key: 'committee',
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return String(record.committee)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                    String(record.rep)
                      .toLowerCase()
                      .includes(value.toLowerCase()) ||
                    String(record.email)
                      .toLowerCase()
                      .includes(value.toLowerCase()) ||
                    String(record.notes)
                      .toLowerCase()
                      .includes(value.toLowerCase());
            },
        },
        {
            title: 'Chamber',
            dataIndex: 'chamber',
            key: 'chamber',
        },
        {
            title: 'Representative',
            dataIndex: 'rep',
            key: 'rep',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Additional Committees/Former Admin',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const getContactData = async () => {
        const contactArr = [];
        const houseDoc = await db.collection('contacts').doc('houseCommittees').get();
        const senateDoc = await db.collection('contacts').doc('senateCommittees').get();

        const houseData = houseDoc.data()?.committees || [];
        const senateData = senateDoc.data()?.committees || [];

        houseData.forEach(doc => {
            contactArr.push({ ...doc, chamber: 'House' });
        });

        senateData.forEach(doc => {
            contactArr.push({ ...doc, chamber: 'Senate' });
        });

        setData(contactArr);
    };

    useEffect(() => {
        getContactData();
    }, []);

    const rowClicked = (record) => {
        setInfo(record);
        setOpen(true);
    };

    const onSubmit = async (saveData) => {
        const chamber = saveData.chamber === 'House' ? 'houseCommittees' : 'senateCommittees';
        const docRef = db.collection('contacts').doc(chamber);
        const docSnap = await docRef.get();
        const committees = [...(await docSnap.data()?.committees || [])];
    
        // Check if info.id exists and is in the array
        if (info.id) {
            const index = committees.findIndex(item => item.id === info.id);
            if (index !== -1) {
                committees[index] = { ...saveData };  // Create a copy of saveData to avoid direct mutation
            }
        } else {
            // Assign a unique ID for new entries
            saveData.id = new Date().getTime().toString();
            committees.push({ ...saveData });  // Create a copy of saveData to avoid direct mutation
        }
    
        await docRef.update({ committees });
        setInfo({})
        getContactData();
        setOpen(false);
    };          

    const onDelete = async () => {
        const chamber = info.chamber === 'House' ? 'houseCommittees' : 'senateCommittees';
        const docRef = db.collection('contacts').doc(chamber);
        const docSnap = await docRef.get();
        const committees = docSnap.data()?.committees || [];

        const updatedCommittees = committees.filter(item => item.id !== info.id);

        await docRef.update({ committees: updatedCommittees });

        setInfo({})
        getContactData();
        setOpen(false);
    };

    return (
        <div>
            <Input.Search
                placeholder='Search Contacts'
                style={{ marginBottom: 8 }}
                onSearch={(value) => setSearchedText(value)}
                onChange={(e) => setSearchedText(e.target.value)}
            />
            <Table
                onRow={(record) => ({
                    onClick: () => rowClicked(record),
                })}
                bordered
                size={'small'}
                rowKey="id"
                columns={contactCol}
                dataSource={data}
                pagination={{
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                }}
                scroll={{ y: 500 }}
                footer={() =>
                    <div>
                        <ContactInfoModal
                            info={info}
                            onSubmit={onSubmit}
                            onDelete={onDelete}
                            open={open}
                            setOpen={setOpen}
                        />
                    </div>
                }
            />
        </div>
    );
}

export default ContactInfoTable;