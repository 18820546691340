import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, Select } from 'antd';
import '../../../App.css'

function EditAgModal({ info, onSubmit, open, setOpen }) {
    const [form] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (info.name) {
            setOpen(true)
        }
    }, [info])

    useEffect(() => {
        form.setFieldsValue(info)
    }, [info, form])

    const showModal = () => {
        setIsDisabled(false)
        form.setFieldsValue({
            key: `${new Date().getTime()}`,
            name: null,
        })
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onFinish = (data) => {
        onSubmit(data);
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                New Entry
      </Button>
            <Modal
                open={open}
                title={'Edit State Agency'}
                onCancel={handleCancel}
                width={750}
                footer={null}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="ID" name='key'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="Name" name='name'>
                            <Input />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 120 }}>
                            <Button block type='primary' htmlType='submit'>
                                Save
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default EditAgModal

