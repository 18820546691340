import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { db, storage } from '../../../db/db';
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import OOGOrdersModal from './OOGOrdersModal'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function OOGOrdersTable() {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [submitting, setSubmitting] = useState();
  const [noDate, setNoDate] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([])
  const [fileList, setFileList] = useState()

  const oogCol = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.subject)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.emailBody)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.start)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: 'Date',
      dataIndex: 'start',
      key: 'date',
      width: 200,
      render: date => date ? dayjs(date).utc().tz('America/New_York').format("YYYY-MM-DD hh:mm a") : null
    },
    {
      title: 'EO Link',
      key: 'emailBody',
      dataIndex: 'emailBody',
      ellipsis: true,
    },
  ];

  const getOOGData = () => {
    const oogArr = [];
    db.collection('oogOrders').get()
      .then(result => result.docs)
      .then(docs => docs.map(doc => doc.data()))
      .then(docs => {
        for (let i = 0; i < docs.length; i += 1) {
          const docsArr = [];
          if (docs[i].extraDocuments?.length > 0) {
            for (let j = 0; j < docs[i].extraDocuments.length; j += 1) {
              docsArr.push(docs[i].extraDocuments[j])
            }
          }
          oogArr.push({
            pageType: 'Office of the Governor',
            key: i,
            id: docs[i].id,
            subject: docs[i].subject,
            start: docs[i].start,
            emailBody: docs[i].emailBody,
            noDate: docs[i].noDate,
            docCount: docs[i].extraDocuments?.length,
            extraDocuments: docsArr,
          })
        }
        oogArr.sort(function (a, b) {
          var dateA = new Date(a.start);
          var dateB = new Date(b.start);
          if (!b.start) {
            return 1;
          }
          if (!a.start) {
            return -1;
          }
          return dateB - dateA;
        });
        setData(oogArr)
      })
  }

  useEffect(() => {
    getOOGData()
  }, [])

  const rowClicked = (e, record) => {
    const data = {
      ...record,
      start: record.start ? record.start : dayjs().utc().tz('America/New_York')
    }
    setNoDate(record.noDate ? record.noDate : false)
    setInfo(data)
  };

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter(file => file.status !== "error"));

  const onRemove = async file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const storageUpload = async (saveData) => {
    setSubmitting(true);
    if (fileList?.length > 0) {
      const fileArr = [];
      await Promise.all(
        fileList?.map(async file => {
          const fileName = `${file.name}-${Date.now()}`
          const pdfRef = ref(storage, `${saveData.id}/${fileName}`);
          try {
            const upload = await uploadBytes(pdfRef, file.originFileObj)
            const downloadUrl = await getDownloadURL(pdfRef);
            const item = {
              name: fileName,
              url: downloadUrl,
            };
            fileArr.push(item)
          } catch (e) {
            console.log(e);
          }
        })
      )
      return fileArr
    } else {
      return []
    }
  }

  const handleDeleteFiles = (fileName) => {
    const newArr = [...itemsToDelete, fileName]
    setItemsToDelete(newArr)
    setInfo({
      ...info,
      extraDocuments: info.extraDocuments.filter(item => item.name !== fileName)
    })
  };

  const onSubmitDeleteFiles = () => {
    for (let i = 0; i < itemsToDelete.length; i += 1) {
      const pdfRef = ref(storage, `${info.id}/${itemsToDelete[i]}`)
      deleteObject(pdfRef).then(() => {
        // File deleted successfully
      })
    }
    setItemsToDelete([])
  };

  const deleteEvent = (id, files) => {
    for (let i = 0; i < files.length; i += 1) {
      const pdfRef = ref(storage, `${id}/${files[i].name}`)
      deleteObject(pdfRef).then(() => {
        // File deleted successfully
      }).catch((error) => {
        alert("Error removing extra documents!");
      });
    }
    db.collection('oogOrders').doc(id).delete().then(() => {
      alert("Entry successfully deleted!");
      getOOGData();
      setOpen(false);
      setFileList([]);
    }).catch((error) => {
      alert("Error removing entry!");
    });
  };

  const onSubmit = async (saveData) => {
    try {
      const storedFiles = await storageUpload(saveData)
      const date = saveData.start ? dayjs(saveData.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : null;
      db.collection('oogOrders').doc(saveData.id).set({
        id: saveData.id ? saveData.id : null,
        emailBody: saveData.emailBody ? saveData.emailBody : null,
        start: noDate ? null : date,
        subject: saveData.subject ? saveData.subject : null,
        noDate: noDate,
        extraDocuments: info.extraDocuments ? [...storedFiles, ...info.extraDocuments] : [...storedFiles],
      })
        .then(() => {
          onSubmitDeleteFiles()
          getOOGData();
          setFileList([]);
          setOpen(false);
        })
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const centerPagination = "bottomCenter";
  return (
    <div>
      <Input.Search
        placeholder='Search Executive Orders'
        style={{ marginBottom: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value)
        }}
      />
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            rowClicked(event, record);
          },
        })}
        bordered
        size={'small'}
        columns={oogCol}
        dataSource={data}
        pagination={{
          position: [centerPagination],
          hideOnSinglePage: true,
        }}
        scroll={{ y: 500 }}
        footer={() =>
          <OOGOrdersModal
            info={info}
            setInfo={setInfo}
            tableType={'Order'}
            onSubmit={onSubmit}
            open={open}
            setOpen={setOpen}
            submitting={submitting}
            noDate={noDate}
            setNoDate={setNoDate}
            handleChange={handleChange}
            onRemove={onRemove}
            handleDeleteFiles={handleDeleteFiles}
            setFileList={setFileList}
            fileList={fileList}
            deleteEvent={deleteEvent}
          />
        }
      />
    </div>
  );
}

export default OOGOrdersTable;