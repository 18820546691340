import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { db } from '../../../db/db';
import CommitteeModal from "./CommitteeModal";

function CommitteesTable() {
    const [data, setData] = useState([]);
    const [info, setInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [searchedText, setSearchedText] = useState("");

    const committeeCol = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return String(record.name)
                    .toLowerCase()
                    .includes(value.toLowerCase())
            }
        },
        {
            title: 'Chamber',
            dataIndex: 'chamber',
            key: 'chamber',
        },
    ];

    const getCommitteeData = () => {
        const committeeArr = [];
        db.collection('committees').doc('1031').get()
            .then(result => result.data().committees)
            .then(docs => {
                for (let i = 0; i < docs.length; i += 1) {
                    committeeArr.push({
                        id: docs[i].id,
                        chamber: docs[i].chamber,
                        sessionId: docs[i].sessionId,
                        name: docs[i].name,
                    })
                }
                setData(committeeArr)
            })
    }

    useEffect(() => {
        getCommitteeData()
    }, [])

    const rowClicked = (e, record) => {
        const data = {
            ...record
        }
        setInfo(data)
    };

    const onSubmit = async (saveData) => {
        const objIndex = data.findIndex((obj => obj.id == saveData.id));
        if (objIndex === -1) {
            data.push({
                name: saveData.name,
                chamber: saveData.chamber,
                sessionId: 1031,
                id: saveData.id,
            })
        } else {
            data[objIndex].name = saveData.name
            data[objIndex].id = saveData.id
            data[objIndex].chamber = saveData.chamber
            data[objIndex].sessionId = 1031
        }
        db.collection('committees').doc('1031').update({ committees: data })
            .then(() => {
                getCommitteeData();
                setOpen(false);
            })
    };

    const centerPagination = "bottomCenter";
    return (
        <div>
            <Input.Search
                placeholder='Search Committees'
                style={{ marginBottom: 8 }}
                onSearch={(value) => {
                    setSearchedText(value);
                }}
                onChange={(e) => {
                    setSearchedText(e.target.value)
                }}
            />
            <Table
                onRow={(record) => ({
                    onClick: (event) => {
                        rowClicked(event, record);
                    },
                })}
                bordered
                size={'small'}
                rowKey="id"
                columns={committeeCol}
                dataSource={data}
                pagination={{
                    position: [centerPagination],
                    hideOnSinglePage: true,
                }}
                scroll={{ y: 500 }}
                footer={() =>
                    <CommitteeModal
                        info={info}
                        tableType={'Committee'}
                        onSubmit={onSubmit}
                        open={open}
                        setOpen={setOpen}
                    />
                }
            />
        </div>
    );
}

export default CommitteesTable;