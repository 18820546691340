import React, { useEffect } from 'react'
import { Button, Modal, Form, Input, DatePicker, Checkbox, Upload, List, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../App.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(utc)

function OOGOrdersModal(props) {
    const {
        info,
        setInfo,
        tableType,
        onSubmit,
        open,
        setOpen,
        noDate,
        setNoDate,
        handleDeleteFiles,
        fileList,
        handleChange,
        onRemove,
        submitting,
        setFileList,
        deleteEvent,
    } = props
    const [form] = Form.useForm();

    useEffect(() => {
        if (info.subject) {
            setOpen(true)
        }
    }, [info])

    useEffect(() => {
        form.setFieldsValue(info)
    }, [info, form])

    const showModal = () => {
        setInfo({
            id: `${new Date().getTime()}`,
            subject: null,
            start: dayjs().utc().tz('America/New_York'),
            location: null,
            emailBody: null,
            noDate: null,
        })
        setNoDate(true)
        setOpen(true);
        setFileList([]);
    };

    const onCheckboxChange = (e) => {
        setNoDate(e.target.checked);
      };

    const handleCancel = () => {
        setOpen(false);
        setFileList([]);
    };

    const onFinish = (data) => {
        onSubmit(data);
    };

    const deleteFile = (file) => {
        handleDeleteFiles(file)
    };

    const confirmDelete = (e) => {
        deleteEvent(form.getFieldsValue().id, info.extraDocuments)
    };
    const cancelDelete = (e) => {
        return
    };

    const beforeUpload = file => {
        // change to check for pdf
        if (file.type !== "application/pdf") {
            console.log(`${file.name} is not a valid image type`, 2);
            return null;
        }
        return false;
    };
    
    return (
        <>
            <Button className='modalButton' type="primary" onClick={showModal}>
                New Entry
      </Button>
            <Modal
                style={{ top: 10 }}
                open={open}
                title={`Edit ${tableType}`}
                onCancel={handleCancel}
                width={800}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="ID" name='id'>
                            <Input disabled={true}/>
                        </Form.Item>
                        <Form.Item label="Subject" name='subject'>
                            <Input />
                        </Form.Item>
                        <Checkbox style={{ marginLeft: 147, marginBottom: 12 }} checked={noDate} onChange={onCheckboxChange}>No Date</Checkbox>
                        <Form.Item
                            label="Date"
                            name="start"
                            style={{ display: noDate ? 'none' : 'block' }}
                            getValueProps={(i) => ({
                                value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                            })}
                        >
                            <DatePicker
                                allowClear={false}
                                itialValue={dayjs().utc().tz('America/New_York')}
                                disabled={noDate}
                                format="YYYY-MM-DD"
                                />
                        </Form.Item>
                        <Form.Item label="EO Link" name='emailBody'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Extra Documents" style={{ marginTop: 50 }}>
                            <div style={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: 3 }}>
                                <Upload
                                    fileList={fileList}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    onRemove={onRemove}
                                    // multiple={true}
                                >
                                    <Button disabled={submitting ? true : false} icon={<UploadOutlined />}>{submitting ? "Uploading" : "Upload"}</Button>
                                </Upload>
                                <List
                                    style={{ marginTop: 10 }}
                                    bordered
                                    dataSource={info.extraDocuments}
                                    renderItem={(item) =>
                                        <List.Item key={item.name} actions={[<Button onClick={() => deleteFile(item.name)}>Delete</Button>]}>
                                            {item.name}
                                        </List.Item>
                                    }
                                />
                            </div>
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 160, marginTop: 70 }}>
                            <Button type='primary' htmlType='submit' style={{ width: 200 }}>
                                Save Entry
                            </Button>
                            <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this event?"
                                onConfirm={confirmDelete}
                                onCancel={cancelDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger style={{ marginLeft: 10, width: 200 }}>
                                    Delete Entry
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default OOGOrdersModal
