import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import '../../../App.css';

function ContactInfoModal({ info, onSubmit, onDelete, open, setOpen }) {
    const [form] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (info.committee) {
            setOpen(true);
        }
    }, [info]);

    useEffect(() => {
        form.setFieldsValue(info);
    }, [info, form]);

    const showModal = () => {
        setIsDisabled(false);
        form.setFieldsValue({
            id: '',
            chamber: 'House',
            committee: '',
            rep: '',
            phone: '',
            email: '',
            notes: '',
        });
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onFinish = (data) => {
        onSubmit(data);
    };

    const handleDelete = () => {
        onDelete();
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                New Entry
            </Button>
            <Modal
                open={open}
                title={'Edit Contact'}
                onCancel={handleCancel}
                width={800}
                footer={null}
                style={{ top: 20 }} // Adjust the value to move the modal higher or lower
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="ID" name='id'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="Chamber" name='chamber'>
                            <Select
                                options={[
                                    { value: 'House', label: 'House' },
                                    { value: 'Senate', label: 'Senate' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="Committee" name='committee'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Representative" name='rep'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Phone" name='phone'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Email" name='email'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Additional Comms" name='notes'>
                            <Input />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 120 }}>
                            <Button block type='primary' htmlType='submit'>
                                Save Contact
                            </Button>
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 120 }}>
                            <Button block danger onClick={handleDelete}>
                                Delete Contact
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default ContactInfoModal;