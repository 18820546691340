import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import TabChooser from '../components/admin/TabChooser'
import '../App.css'
import AdminLogin from '../components/login/AdminLogin'
import { signOut } from "firebase/auth";
import { auth, db } from '../db/db';

function Admin() {
    const [content, setContent] = useState(<div></div>)

    const handleLogout = () => {
        signOut(auth).then(() => {
            console.log('signed out')
            // Sign-out successful.
        }).catch((error) => {
            console.log("error")
            // An error happened.
        });
    }

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                db.collection('users').doc(user.email).get()
                    .then(result => result.data())
                    .then(doc => {
                        if (doc.role === 'admin') {
                            setContent(<TabChooser />);
                        } else {
                            setContent(
                                <div>
                                    <h2>You do not have permission to view this page</h2>
                                    <Button onClick={handleLogout} icon={<UserOutlined />}>Log Out</Button>
                                </div>
                            )
                        }
                    })
            } else {
                setContent(
                    <div className='loginContainer'>
                        <AdminLogin />
                    </div>
                );
            }
        });
    }, []);

    return (
        <div className="App">
            {content}
        </div>
    )
}

export default Admin
