import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { db } from '../../../db/db';
import WebCalModal from './WebCalModal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(utc);

function WebCalTable({ committeeData }) {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const eventCol = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: 320,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.subject)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.billsResolutions)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.committees)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.start)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: 'Date/Time',
      dataIndex: 'start',
      key: 'date',
      width: 100,
      render: date => date ? dayjs(date).utc().tz('America/New_York').format("YYYY-MM-DD hh:mm a") : null
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      width: 100,
    },
    {
      title: 'Livestream Url',
      key: 'livestreamUrl',
      dataIndex: 'livestreamUrl',
      width: 200,
      ellipsis: true,
    },
    {
      title: 'Committees',
      key: 'committees',
      dataIndex: 'committees',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Bills/Resolutions',
      key: 'billsResolutions',
      dataIndex: 'billsResolutions',
      width: 135,
      ellipsis: true,
    },
    {
      title: 'Agenda Link',
      key: 'agendaLink',
      dataIndex: 'agendaLink',
      width: 200,
      ellipsis: true,
    },
  ]

  const getEventData = () => {
    const eventArr = [];
    db.collection('events').get()
      .then(result => result.docs)
      .then(docs => docs.map(doc => doc.data()))
      .then(docs => {
        for (let i = 0; i < docs.length; i += 1) {
          // const committees = docs[i].committees?.join(", ");
          const bills = docs[i].billsResolutions?.join(", ");
          const docsArr = [];
          if (docs[i].extraDocuments?.length > 0) {
            for (let j = 0; j < docs[i].extraDocuments.length; j += 1) {
              docsArr.push(docs[i].extraDocuments[j])
            }
          }
          eventArr.push({
            pageType: 'Event',
            key: docs[i].id,
            subject: docs[i].subject,
            id: docs[i].id,
            start: docs[i].start,
            body: docs[i].body,
            chamber: docs[i].chamber,
            isVimeo: docs[i].isVimeo,
            willBroadcast: docs[i].willBroadcast,
            location: docs[i].location,
            livestreamUrl: docs[i].livestreamUrl,
            committees: docs[i].committees,
            billsResolutions: bills,
            agendaLink: docs[i].agendaUri,
            extraDocuments: docsArr,
          })
        }
        eventArr.sort(function (a, b) {
          var dateA = new Date(a.start);
          var dateB = new Date(b.start);
          if (!b.start) {
            return 1;
          }
          if (!a.start) {
            return -1;
          }
          return dateB - dateA;
        });
        setData(eventArr)
      })
  }

  useEffect(() => {
    getEventData()
  }, [])

  const rowClicked = (e, record) => {
    const data = {
      ...record
    }
    setInfo(data)
  };

  // will be adding the adminAdded flag from admin table save button
  const onSubmit = (saveData) => {
    const date = saveData.start ? dayjs(saveData.start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : null;
    const bills = info.billsResolutions?.split(', ')
    db.collection('events').doc(saveData.id).set({
      id: saveData.id ? saveData.id : null,
      agendaUri: saveData.agendaLink ? saveData.agendaLink : null,
      livestreamUrl: saveData.livestreamUrl ? saveData.livestreamUrl : null,
      location: saveData.location ? saveData.location : null,
      start: date ? date : null,
      subject: saveData.subject ? saveData.subject : null,
      committees: saveData.committees ? saveData.committees : saveData.committees !== null ? saveData.committees : null,
      billsResolutions: bills ? bills : null,
      body: info.body ? info.body : null,
      chamber: info.chamber ? info.chamber : null,
      isVimeo: info.isVimeo ? info.isVimeo : null,
      willBroadcast: info.willBroadcast ? info.willBroadcast : null,
      extraDocuments: info.extraDocuments ? info.extraDocuments : null,
      ignoreListener: false,
    })
      .then(() => {
        getEventData();
        setOpen(false);
      })
  };

  const centerPagination = "bottomCenter";
  return (
    <div>
      <Input.Search
        placeholder='Search Web Calendar Events'
        style={{ marginBottom: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value)
        }}
      />
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            rowClicked(event, record);
          },
        })}
        bordered
        size={'small'}
        columns={eventCol}
        dataSource={data}
        pagination={{
          position: [centerPagination],
          hideOnSinglePage: true,
        }}
        scroll={{ y: 500 }}
        footer={() =>
          <WebCalModal
            info={info}
            tableType={'WebCal Event'}
            onSubmit={onSubmit}
            open={open}
            setOpen={setOpen}
            committeeData={committeeData}
          />
        }
      />
    </div>
  );
}

export default WebCalTable;